// src/components/PurchaseDetails.js
import React from 'react';
import './PurchaseDetails.css';

function PurchaseDetails() {
  return (
    <section id="purchase-details" className="purchase-details">
      <div className="content">
        <h2>Claim Your Digital Masterpiece: Own the Legacy</h2>
        <p>
        Step into history by claiming a piece of this unique NFT collection. Each digital artwork not only symbolises the legacy of The Immortal, a man who spent decades behind bars, but also celebrates his leap to Hollywood stardom. This is more than an NFT; it’s a ticket into a remarkable journey from prison cell to the big screen, where The Immortal is set to star in a Marvel movie. Be part of this groundbreaking event and own a slice of this astonishing transformation.

        </p>
      </div>
    </section>
  );
}

export default PurchaseDetails;
