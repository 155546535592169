import React, { useState } from 'react';
import './Navbar.css';
import logo from './logo.webp'; // Import the logo image

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <img src={logo} alt="NFT Project Logo" className="logo-image" /> {/* Logo Image */}
        <h2 className="logo">Skint Cuntz</h2>
      </div>
      <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
        <li><a href="#gallery" onClick={toggleMenu}>Gallery</a></li>
        <li><a href="#purchase-details" onClick={toggleMenu}>Purchase</a></li>
        <li><a href="#pricing-availability" onClick={toggleMenu}>Pricing</a></li>
        <li><a href="#transaction-process" onClick={toggleMenu}>Transaction</a></li>
        <li><a href="#ownership-transfer" onClick={toggleMenu}>Ownership</a></li>
        <li><a href="#discord" onClick={toggleMenu}>Community</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
