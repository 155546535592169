// src/App.js
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Gallery from './components/Gallery';
import PurchaseDetails from './components/PurchaseDetails';
import PricingAvailability from './components/PricingAvailability';
import TransactionProcess from './components/TransactionProcess';
import OwnershipTransfer from './components/OwnershipTransfer';
import DiscordSection from './components/DiscordSection';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Gallery />
      <PurchaseDetails />
      <PricingAvailability />
      <TransactionProcess />
      <OwnershipTransfer />
      <DiscordSection />
      <Footer />
    </div>
  );
}

export default App;
