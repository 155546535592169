// src/components/PricingAvailability.js
import React from 'react';
import './PricingAvailability.css';
import image from './5.png'; // Use the placeholder image

function PricingAvailability() {
  return (
    <section id="pricing-availability" className="pricing-availability">
      <div className="content">
        <div className="text">
          <h2>Value and Exclusivity: Unmatched Rarity, Unstoppable Story</h2>
          <p>
          This NFT collection is more than exclusive — it’s historic. Each token carries the weight of The Immortal’s notorious past and his transition to becoming a cultural phenomenon. Owning one of these NFTs means holding a piece of the first-ever real-life figures to become a Marvel star. With his upcoming role already making waves in Hollywood, the value of these tokens goes beyond art; they’re a testament to a life redefined and a future that merges reality with comic-book legend. This makes each NFT a piece for collectors and fans alike.
          </p>
        </div>
        <div className="image-container">
          <img src={image} alt="Placeholder" />
        </div>
      </div>
    </section>
  );
}

export default PricingAvailability;
