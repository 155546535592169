// src/components/Hero.js
import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Discover the Art: From Notoriety to Icon</h1>
        <p>
        Dive into an NFT collection like no other, spotlighting the life and transformation of one of the most notorious figures in history, The Immortal.
        </p>
        <button className="cta-button">Explore NFTs</button>
      </div>
    </section>
  );
}

export default Hero;
