// src/components/TransactionProcess.js
import React from 'react';
import './TransactionProcess.css';

function TransactionProcess() {
  return (
    <section id="transaction-process" className="transaction-process">
      <div className="content">
        <h2>How to Make It Yours: Secure Your Place in History</h2>
        <p>
    
          Join an elite group of collectors on <a href="https://opensea.io/SkintCuntz" target="_blank" rel="noopener noreferrer">OpenSea</a>, the world’s largest NFT marketplace, where this exclusive collection is available. Purchasing one of these NFTs is simple, but the impact is monumental. As The Immortal prepares to step onto the global stage as a Marvel star, you have the chance to secure your own slice of this extraordinary tale. Follow our easy steps, complete your purchase on OpenSea, and become part of a narrative that defies convention, blurring the lines between the man and the legend.

        </p>
        {/* Steps to purchase can be listed here */}
      </div>
    </section>
  );
}

export default TransactionProcess;
